<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('Hosts')"
    @cancel="onCancel('Hosts')"
  >
  <template #ip>
    <p class="ip-column__description">
      IP-адрес
    </p>
    <div class="ip-column__input-wrapper">
      <sm-input
      v-model="form.ip"
      type="text"
      @input="validateIpAddress"
    />
    <p v-if="ipError" class="ip-column__error-message">{{ ipError }}</p>
    </div>
  </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
// utils
import { isValidIpAddress } from '@/utils/validateIpAddress';
import { validateFormMandatoryFields } from '@/utils/validateFormMandatoryFields';

export default {
  name: 'HostCreate',

  components: {
    SmEditableItem,
    SmInput,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Hosts',
      pageHeader: 'Создание хоста',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Хосты',
          route: { name: 'Hosts' },
        },
        {
          text: 'Создание хоста',
        },
      ],
      initialForm: {
        name: null,
        ip: null,
        description: null,
      },
      form: null,
      ipError: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Имя',
            },
            {
              type: 'text',
              key: 'ip',
              label: 'IP-адрес',
              attributes: {
                placeholder: '###.###.###.###',
              }
            },
            {
              type: 'textarea',
              key: 'description',
              label: 'Описание',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem'
    }),

    validateIpAddress() {
      this.ipError = !isValidIpAddress(this.form.ip) ? 'Некорректный IP-адрес' : null;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'name', label: 'Имя' }];

      const validationError = validateFormMandatoryFields(this.form, mandatoryFields);
      if (validationError) {
        this.showNotification(validationError, 'error');
        return;
      }

      this.processSave(route);
    },

    showNotification(header, type) {
      this.$notify({
        header,
        type,
        timer: 5000,
      });
    },

    async processSave(route) {
      this.isLoadingPage = true;
      try {
        const result = await this.createItem({
          name: this.controllerName,
          payload: this.form,
        });
        if (result.isSucceed) {
          this.$router.push({ name: route });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },
  },
};
</script>

<style lang="scss">
.ip-column__description {
  width: 250px;
  align-self: center;
}
.ip-column__input-wrapper {
  width: 100%;
}
.ip-column__error-message{
  color: var(--red);
  font-size: 12px;
}
</style>
